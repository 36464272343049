var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('ControlNavbar'),(_vm.mapObject)?_c('div',{attrs:{"id":"map"}},[_c('gmap-map',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":12}},[(_vm.mapObject.data.fitClosest)?_c('GoogleMapCluster',[_vm._l((_vm.mapObject.data.pins),function(pin,index){return _c('gmap-marker',{key:index,staticClass:"marker_custom",attrs:{"position":{ lat: pin.latitude, lng: pin.longitude },"icon":{
            url: pin.image,
            size: { width: 30, height: 20, f: 'px', b: 'px' },
            scaledSize: { width: 30, height: 20, f: 'px', b: 'px' },
          }},on:{"click":function($event){return _vm.toggleInfoWindow(pin)}}})}),_c('gmap-info-window',{attrs:{"position":_vm.position,"opened":_vm.opened,"options":_vm.infoOptions},on:{"closeclick":function($event){_vm.opened = false}}},[_c('h6',[_vm._v(_vm._s(_vm.content.title))]),_c('p',[_vm._v(_vm._s(_vm.content.text))]),(_vm.mapObject.data.navigation)?_c('p',[_c('a',{attrs:{"target":"_blank","href":("https://www.google.com/maps/dir/?api=1&origin=" + (_vm.center.lat) + "," + (_vm.center.lng) + "&destination=" + (_vm.position.lat) + "," + (_vm.position.lng))}},[_vm._v(_vm._s(_vm.locale.goto))])]):_vm._e()])],2):_vm._e(),(!_vm.mapObject.data.fitClosest)?_c('div',[_vm._l((_vm.mapObject.data.pins),function(pin,index){return _c('gmap-marker',{key:index,staticClass:"marker_custom",attrs:{"position":{ lat: pin.latitude, lng: pin.longitude },"icon":{
            url: pin.image,
            size: { width: 30, height: 20, f: 'px', b: 'px' },
            scaledSize: { width: 30, height: 20, f: 'px', b: 'px' },
          }},on:{"click":function($event){return _vm.toggleInfoWindow(pin)}}})}),_c('gmap-info-window',{attrs:{"position":_vm.position,"opened":_vm.opened,"options":_vm.infoOptions},on:{"closeclick":function($event){_vm.opened = false}}},[_c('h6',[_vm._v(_vm._s(_vm.content.title))]),_c('p',[_vm._v(_vm._s(_vm.content.text))]),(_vm.mapObject.data.navigation)?_c('p',[_c('a',{attrs:{"target":"_blank","href":("https://www.google.com/maps/dir/?api=1&origin=" + (_vm.center.lat) + "," + (_vm.center.lng) + "&destination=" + (_vm.position.lat) + "," + (_vm.position.lng))}},[_vm._v(_vm._s(_vm.locale.goto))])]):_vm._e()])],2):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }