<template>
  <div class="">
    <ControlNavbar/>
    <div class="" id="map" v-if="mapObject">
      <gmap-map :center="center" :zoom="12" style="width: 100%; height: 100%">
        <GoogleMapCluster v-if="mapObject.data.fitClosest">
          <gmap-marker
              :key="index"
              class="marker_custom"
              v-for="(pin, index) in mapObject.data.pins"
              :position="{ lat: pin.latitude, lng: pin.longitude }"
              :icon="{
              url: pin.image,
              size: { width: 30, height: 20, f: 'px', b: 'px' },
              scaledSize: { width: 30, height: 20, f: 'px', b: 'px' },
            }"
              @click="toggleInfoWindow(pin)"
          >
          </gmap-marker>
          <gmap-info-window
              :position="position"
              :opened="opened"
              :options="infoOptions"
              @closeclick="opened = false"
          >
            <h6>{{ content.title }}</h6>
            <p>{{ content.text }}</p>
            <p v-if="mapObject.data.navigation">
              <a
                  target="_blank"
                  :href="
                  `https://www.google.com/maps/dir/?api=1&origin=${center.lat},${center.lng}&destination=${position.lat},${position.lng}`
                "
              >{{ locale.goto }}</a
              >
            </p>
          </gmap-info-window>
        </GoogleMapCluster>
        <div v-if="!mapObject.data.fitClosest">
          <gmap-marker
              :key="index"
              class="marker_custom"
              v-for="(pin, index) in mapObject.data.pins"
              :position="{ lat: pin.latitude, lng: pin.longitude }"
              :icon="{
              url: pin.image,
              size: { width: 30, height: 20, f: 'px', b: 'px' },
              scaledSize: { width: 30, height: 20, f: 'px', b: 'px' },
            }"
              @click="toggleInfoWindow(pin)"
          >
          </gmap-marker>
          <gmap-info-window
              :position="position"
              :opened="opened"
              :options="infoOptions"
              @closeclick="opened = false"
          >
            <h6>{{ content.title }}</h6>
            <p>{{ content.text }}</p>
            <p v-if="mapObject.data.navigation">
              <a
                  target="_blank"
                  :href="
                  `https://www.google.com/maps/dir/?api=1&origin=${center.lat},${center.lng}&destination=${position.lat},${position.lng}`
                "
              >{{ locale.goto }}</a
              >
            </p>
          </gmap-info-window>
        </div>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import GoogleMapCluster from "vue2-google-maps/dist/components/cluster";

import axios from "axios";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";

export default {
  name: "Map",
  components: {ControlNavbar, GoogleMapCluster},
  data() {
    return {
      locale: null,
      id: null,
      mapObject: null,
      center: {lat: 45.508, lng: -73.587},
      opened: false,
      content: {title: "", text: ""},
      position: {lat: 45.508, lng: -73.587},
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    toggleInfoWindow: function (marker) {
      this.opened = true;
      this.position = {lat: marker.latitude, lng: marker.longitude};
      this.content.title = marker.title;
      this.content.text = marker.text;
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    getMap(id) {
      this.getAxios()
          .get(`${this.$session.get("baseURI")}data/map/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `data/map/${id}`, "get")) {
              // ok
              console.log("MAP success: ", result);
              // check if fit closest and navigation on change data accordingly before giving the map object to this
              this.mapObject = result.data;
            }
          });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
  },
  created() {
    this.checkLocal();
    this.geolocate();
    console.log("id: : ", this.$route.params.id || this.$session.get("backup"));
    if (!this.$route.params.id) {
      //undefined take from cache
      this.id = this.$session.get("backup");
      this.getMap(this.id);
    } else {
      // defined
      this.id = this.$route.params.id;
      this.$session.set("backup", this.$route.params.id);
      this.getMap(this.id);
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 92vh;
  position: inherit;
  // margin-top: 3%;
}

// .marker_custom {
//   img {
//     width: 50px;
//   }
// }
</style>
